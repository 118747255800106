
import React, { useContext, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { InfoContext } from './Station';

const CrossSection = () => {
  const {info,setInfo,selectedStation,setSelectedStation} = useContext(InfoContext)
  const crossSection = info.crossSection
  
  const x = crossSection.cross_section.map(c => c.x)
  const y = crossSection.cross_section.map(c => c.y)
  let value = []
  for (let index = 0; index < crossSection.cross_section.length; index++) {
    value.push((crossSection.value + crossSection.zero_gauge).toFixed(2))
    
  }
  const option = {
    /* title: {
      text: 'รูปหน้าตัด สถานีเชียงแสน',
      left: 'center',
          textStyle: {
            fontSize: 16,
            fontFamily: 'Sarabun'
          },
    }, */
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      },
      textStyle: {
        fontSize: 12,
        fontFamily: 'Sarabun'
      },
    },
    legend: {
      data: ['ระดับตลิ่งและระดับท้องน้ำ', 'ระดับน้ำ'],
      itemStyle: {
        color: ["rgba(145, 45, 45, 1)","rgba(255, 45, 45, 1)"]
      },
      textStyle: {
        fontSize: 12,
        fontFamily: 'Sarabun'
      },
    },
    grid: {
      left: '5%',
      right: '2%',
      bottom: '10%',
      containLabel: true,
    },
    xAxis: [
      {
        name: 'ความกว้างของหน้าตัดลำน้ำ (ม.)',
        nameLocation: 'center',
        nameTextStyle: {
        padding: [10, 0, 0, 0]
        },
        type: 'category',
        boundaryGap: false,
        data: x,
      }
    ],
    yAxis: [
      {
        name: 'เมตร ระดับน้ำทะเลปานกลาง (ม.รทก.)',
        nameLocation: 'center',
        nameTextStyle: {
          padding: [0, 0, 35, 0]
          },
        type: 'value',
        min: Math.min(...y) - 2,
        max: Math.max(...y) +3,
        splitLine: {
        show: false
      }
      }
    ],
    series: [
      {
        name: 'ระดับตลิ่งและระดับท้องน้ำ',
        type: 'line',
        smooth: true,
        areaStyle: {
          color: '#C2A887',
          opacity: 1,
        },
        lineStyle: {
          width: 1,
          color: '#C2A887'
        },
        showSymbol: false,
        emphasis: {
          focus: 'series'
        },
        data: y
      },
      {
        name: 'ระดับน้ำ',
        type: 'line',
        z: -1,
        showSymbol: false,
        lineStyle: {
          width: 1,
          color: '#005493'
        },
        areaStyle: {
          color: '#005493',
        },
        data: value,
        markLine:{
        symbol: "none",
        data: [{
            name: 'flood level',
            yAxis: crossSection.flood_level + crossSection.zero_gauge,
            label: {
              normal: {
               show: false, 
              }
            },
            lineStyle: {
            color: "rgba(146, 29, 29, 1)",
          },
        },{
            name: 'alarm level',
            yAxis: crossSection.alarm_level + crossSection.zero_gauge,
            label: {
              normal: {
               show: false, 
              }
            },
            lineStyle: {
            color: "rgba(146, 150, 29, 1)"
          },
        }],
      }
      }
    ]
  };
    return (<ReactECharts option={option} notMerge={true} lazyUpdate={true}  style={{height: '300px', width: '100%'}}/>)};

export default CrossSection;